import React, { useEffect, useRef } from 'react';
import { ProgressBar } from './ProgressBar';

type Props = {
    onNextScreen: VoidFunction;
};

const coeffs = {
    first: 20,
    second: 22,
    third: 24,
};

export const CreateAccountScreen: React.FC<Props> = ({ onNextScreen }) => {
    const [progresses, setProgresses] = React.useState({
        first: 0,
        second: 0,
        third: 0,
    });

    const showNext =
        progresses.first === 100 && progresses.second === 100 && progresses.third === 100;

    const tm = useRef<NodeJS.Timeout | null>();

    const updateLoading = () => {
        tm.current = setTimeout(() => {
            setProgresses((prev) => ({
                first:
                    prev.first >= 99
                        ? 100
                        : Math.ceil(
                              Math.min(prev.first + Math.floor(coeffs.first * Math.random()), 99),
                          ),
                second:
                    prev.second >= 99
                        ? 100
                        : Math.ceil(
                              Math.min(prev.second + Math.floor(coeffs.second * Math.random()), 99),
                          ),
                third:
                    prev.third >= 99
                        ? 100
                        : Math.ceil(
                              Math.min(prev.third + Math.floor(coeffs.third * Math.random()), 99),
                          ),
            }));

            updateLoading();
        }, 700);
    };

    useEffect(() => {
        tm.current && clearTimeout(tm.current);

        updateLoading();

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (progresses.first === 100 && progresses.second === 100 && progresses.third === 100) {
            if (tm.current) {
                clearTimeout(tm.current);
            }
        }

        // eslint-disable-next-line
    }, [progresses]);

    useEffect(() => {
        if (showNext) {
            setTimeout(onNextScreen, 1500);
        }
    }, [showNext, onNextScreen]);

    return (
        <div className="flex flex-col w-full h-full gap-4 p-4">
            <div className="flex flex-col w-full">
                <div className="flex flex-col gap-3 pt-5">
                    <p className="font-druk text-left text-white text-2xl font-bold leading-7">
                        Setting up new
                    </p>
                    <p className="font-druk text-left text-white text-2xl font-bold leading-7">
                        account
                    </p>
                </div>
            </div>
            <div className="flex flex-col gap-5">
                <div className="flex flex-col gap-1">
                    <span className="font-mono text-base text-[#E6E6E6]">
                        🦠 Activating Roach incubation
                    </span>
                    <div className="">
                        <ProgressBar progress={progresses.first} />
                    </div>
                </div>
                <div className="flex flex-col gap-1">
                    <span className="font-mono text-base text-[#E6E6E6]">
                        🧬 Calculating unique Roach DNA
                    </span>
                    <div className="">
                        <ProgressBar progress={progresses.second} />
                    </div>
                </div>
                <div className="flex flex-col gap-1">
                    <span className="font-mono text-base text-[#E6E6E6]">
                        🧪 Activating $MTGN farming
                    </span>
                    <div className="">
                        <ProgressBar progress={progresses.third} />
                    </div>
                </div>
            </div>
        </div>
    );
};
