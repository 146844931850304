import { useEffect, useMemo } from 'react';
import { CreateAccountScreen } from './CreateAccountScreen';
import { AccountScreen } from './AccountScreen/AccountScreen';
import { doSignIn } from '../../helpers/api';
import { useHandleError } from '../../hooks/useHandleError';
import { Config } from '../../helpers/store';
import { useStore } from '../../store/globalStore';
import { UserStatus } from '../../api/tor-api';
import { FirstScreenFixed } from './FirstScreen/FirstScreen';

enum Screen {
    FIRST = 'FIRST',
    CREATE = 'CREATE',
    ACCOUNT = 'ACCOUNT',
}

export const Main = () => {
    console.log('Main: render');
    const screen = useStore((state) => state.screen);
    const config = useStore((state) => state.config);
    const updateScreen = useStore((state) => state.updateScreen);
    const updateUser = useStore((state) => state.updateUser);
    const setIsShowIntro = useStore((state) => state.setIsShowIntro);
    const setConfig = useStore((state) => state.setConfig);
    const user = useStore((state) => state.user?.telegramId);

    const { handleError } = useHandleError();

    const renderScreen = useMemo(() => {
        console.log('Main.renderScreen');
        switch (screen) {
            case Screen.FIRST:
                return <FirstScreenFixed />;
            case Screen.CREATE:
                return (
                    <CreateAccountScreen
                        onNextScreen={() => {
                            setIsShowIntro(true);
                            updateScreen(Screen.ACCOUNT);
                        }}
                    />
                );
            case Screen.ACCOUNT:
                return <AccountScreen />;
        }
    }, [screen, setIsShowIntro, updateScreen]);

    useEffect(() => {
        console.log('Main.useEffect');
        doSignIn()
            .then((initData) => {
                if (initData) {
                    let newConfig: Config = {
                        ...config,
                        config: initData.config,
                        serverTimeDiffMs:
                            new Date(initData.serverTime).getTime() - new Date().getTime(),
                    };
                    setConfig(newConfig);
                    updateUser(initData.user);

                    if (
                        initData.user.status === UserStatus.REGISTERED ||
                        initData.user.status === UserStatus.INTERACT_WITH_FARMING ||
                        initData.user.status === UserStatus.FARMING_START ||
                        initData.user.status === UserStatus.CLAIMED_FARM_SESSION
                    ) {
                        updateScreen(Screen.ACCOUNT);
                    } else {
                        updateScreen(Screen.FIRST);
                    }
                }
            })
            .catch(handleError);

        // eslint-disable-next-line
    }, []);

    if (!user) {
        console.log('Main: no user');
        return null;
    }

    return <div className="flex w-full text-white h-full flex-col">{renderScreen}</div>;
};
